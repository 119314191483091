import React, { useEffect, useRef, useState } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { useHistory, useParams } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Menu } from "primereact/menu";
import { handleGetRequest } from "../../services/GetTemplate";
import moment from "moment";
import { handlePostRequest } from "../../services/PostTemplate";
import { handlePutRequest } from "../../services/PutTemplate";
import { toast } from "react-toastify";

function OrderItem() {
    const [selectedRow, setselectedRow] = useState([]);
    const [status, setStatus] = useState("");
    const home = { icon: "pi pi-home", url: "/" };
    const [resData, setResData] = useState([]);
    const [orderList, setOrderList] = useState([]);
    const [role, setRole] = useState("");
    const breadItems = [{ label: "Home" }, { label: `Order ${resData?.order_id}` }];
    const history = useHistory();
    const { id } = useParams();
    useEffect(() => {
        const order = localStorage.getItem("orderItem");
        setResData(JSON.parse(order));
    }, []);

    useEffect(() => {
        setStatus(resData?.status);
    }, [resData]);
    const handledClicked = () => {
        history.push("");
    };

    console.log(resData);


    useEffect(() => {
        const role = localStorage.getItem("role");
        setRole(role);
    }, []);
    console.log(status);
    return (
        <>
            <div className="Page__Header" style={{ marginTop: "40px" }}>
                <div>
                    <h2>Order&nbsp;#{resData?.order_id}</h2>
                    
                </div>
                
            </div>
            <div className="row__">
                <div className="col_left">
                    <p>id</p>
                    <p>{resData?.order_id}</p>
                </div>
                <div className="col_right">
                    <p>price</p>
                    <p>{resData?.price}</p>
                </div>
            </div>  
            <div className="row__">
                <div className="col_left">
                    <p>Updated On</p>
                    <p>
                        {moment(resData?.updatedAt).format("DD-MM-YY")} {moment(resData?.updatedAt).format("hh:mm a")}
                    </p>
                </div>
                <div className="col_right">
                    <p>Created bY</p>
                    <p>
                        {resData?.first_name} {resData?.last_name}
                    </p>
                </div>        
            </div>
            <div className="row__">
                <div className="col_left">
                    <p>Tyre ID</p>
                    <p>
                        {resData?.items?.product?._id}
                    </p>
                </div>
                <div className="col_right">
                    <p>Quantity</p>
                    <p>
                    {resData?.items?.quantity}
                    </p>
                </div>        
            </div>
           
            <div className="sub_header___">
                <div className="_btn_">
                    <div>
                        <p>Tyre Details</p>
                    </div>
                </div>
            </div>

            <div className="row__">
                <div className="col_left">
                    <p>id</p>
                    <p>{resData?.items?.product?._id}</p>
                </div>
                <div className="col_right">
                    <p>Title</p>
                    <p>{resData?.items?.product?.title}</p>
                </div>
            </div>  
            <div className="row__">
                <div className="col_left">
                    <p>Tyre Width</p>
                    <p>
                    {resData?.items?.product?.tyre_width}
                    </p>
                </div>
                <div className="col_right">
                    <p>Aspect Ratio</p>
                    <p>
                    {resData?.items?.product?.tyre_profile}
                    </p>
                </div>        
            </div>
            <div className="row__">
                <div className="col_left">
                    <p>Rim Diameter in Inches</p>
                    <p>
                    {resData?.items?.product?.rim_diameter}
                    </p>
                </div>
                <div className="col_right">
                    <p>Manufacturer</p>
                    <p>
                    {resData?.items?.product?.tyre_manufacturer?.title}
                    </p>
                </div>        
            </div>
            <div className="row__">
                <div className="col_left">
                    <p>Pattern</p>
                    <p>
                    {resData?.items?.product?.pattern?.title}
                    </p>
                </div>
                    
            </div>
        </>
    );
}

export default OrderItem;
