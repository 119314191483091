import React, { useEffect, useRef, useState } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { useHistory, useParams } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Menu } from "primereact/menu";
import { handleGetRequest } from "../../services/GetTemplate";
import moment from "moment";
import { handlePostRequest } from "../../services/PostTemplate";
import { handlePutRequest } from "../../services/PutTemplate";
import { toast } from "react-toastify";

function OrderDetail() {
    const [selectedRow, setselectedRow] = useState([]);
    const [status, setStatus] = useState("");
    const home = { icon: "pi pi-home", url: "/" };
    const [resData, setResData] = useState([]);
    const [orderList, setOrderList] = useState([]);
    const [role, setRole] = useState("");
    const breadItems = [{ label: "Home" }, { label: `Order ${resData?.order_id}` }];
    const history = useHistory();
    const { id } = useParams();
    const getDatabyId = async () => {
        const res = await handleGetRequest(`/orderById/${id}`);
        setResData(res?.data);
        setOrderList(res?.data?.items);
    };
    useEffect(() => {
        getDatabyId();
    }, [id]);

    useEffect(() => {
        setStatus(resData?.status);
    }, [resData]);
    const handledClicked = () => {
        history.push("");
    };
    const menu = useRef(null);
    const items = [
        {
            label: "Options",
            items: [
                {
                    label: "Delete",
                    icon: "pi pi-times",
                },
                {
                    label: "View Details",
                    icon: "pi pi-times",
                    url: "/orderdetail",
                },
            ],
        },
    ];

    const dateTemplate = (rowdata) => {
        return (
            <div>
                <p>
                    {moment(rowdata?.updatedAt).format("DD-MM-YY")} &nbsp; | &nbsp; {moment(rowdata?.updatedAt).format("hh:mm a")}
                </p>
            </div>
        );
    };

    const itemTemplate = (rowData) => {
        return <p>{rowData?.product?.title}</p>;
    };

    const handleCHange = async (value) => {
        setStatus(value);
        const data = {
            id: resData?.order_id,
            status: value,
        };
        const result = await handlePutRequest(data, "/order/changeStatus");
        if (result?.success) {
            toast.success("status changed.");
            getDatabyId();
        }
    };

    useEffect(() => {
        const role = localStorage.getItem("role");
        setRole(role);
    }, []);
    console.log(status);

    const handleNavigate=(data)=>{
        const id=data?._id;
        const temp=resData?.items?.filter((item)=>item._id===id);
        const dat={
            ...resData,
            items:temp?.[0]
        }
        localStorage.setItem("orderItem",JSON.stringify(dat));
        history.push(`/orderItem`)
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div>
                <Button icon="pi pi-eye" className="p-button-rounded mr-2 Elipse_Icon" onClick={() => handleNavigate(rowData)} />
            </div>
        );
    };
    return (
        <>
            <div className="Page__Header" style={{ marginTop: "40px" }}>
                <div>
                    <h2>Order&nbsp;#{resData?.order_id}</h2>
                    <BreadCrumb model={breadItems} home={home} />
                </div>
                <div className="Top__Btn">
                    <Button icon="pi pi-trash" iconPos="right" onClick={handledClicked} className="Btn__DarkDelete" style={{ width: "240px" }} />
                </div>
            </div>
            <div className="row__">
                <div className="col_left">
                    <p>id</p>
                    <p>{resData?.order_id}</p>
                </div>
                <div className="col_right">
                    <p>price</p>
                    <p>{resData?.price}</p>
                </div>
            </div>
            <div className="row__">
                <div className="col_left">
                    <p>country</p>
                    <p>{resData?.country}</p>
                </div>
                <div className="col_right">
                    <p>first name</p>
                    <p>{resData?.first_name}</p>
                </div>
            </div>
            <div className="row__">
                <div className="col_left">
                    <p>last name</p>
                    <p>{resData?.last_name}</p>
                </div>
                <div className="col_right">
                    <p>address</p>
                    <p>{resData?.address}</p>
                </div>
            </div>
            <div className="row__">
                <div className="col_left">
                    <p>city</p>
                    <p>{resData?.city}</p>
                </div>
                <div className="col_right">
                    <p>GSTIN</p>
                    <p>{resData?.GSTN}</p>
                </div>
            </div>
            <div className="row__">
                <div className="col_left">
                    <p>state</p>
                    <p>{resData?.state}</p>
                </div>
                <div className="col_right">
                    <p>Zipcode</p>
                    <p>{resData?.zipcode}</p>
                </div>
            </div>
            <div className="row__">
                <div className="col_left">
                    <p>Email</p>
                    <p>{resData?.email}</p>
                </div>
                <div className="col_right">
                    <p>Phone</p>
                    <p>{resData?.phone}</p>
                </div>
            </div>
            <div className="row__">
                <div className="col_left">
                    <p>Payment Method</p>
                    <p>{resData?.pay_type}</p>
                </div>
                <div className="col_right">
                    <p>Delivery State</p>
                    <p>{resData?.state}</p>
                </div>
            </div>
            <div className="row__">
                <div className="col_left">
                    <p>Updated On</p>
                    <p>
                        {moment(resData?.updatedAt).format("DD-MM-YY")} {moment(resData?.updatedAt).format("hh:mm a")}
                    </p>
                </div>
                <div className="col_right">
                    <p>Created On</p>
                    <p>
                        {moment(resData?.createdAt).format("DD-MM-YY")} {moment(resData?.createdAt).format("hh:mm a")}
                    </p>
                </div>
            </div>
            <div className="row__">
                <div className="col_left">
                    <p>Created bY</p>
                    <p>
                        {resData?.first_name} {resData?.last_name}
                    </p>
                </div>
                <div className="col_right">
                    <p>Delivery Status</p>
                    <select disabled={role === "calling" || role === "digital marketing" ? true : false} className="select" value={status} onChange={(e) => handleCHange(e.target.value)}>
                        <option value="placed" selected={status === "placed" ? true : false}>
                            placed
                        </option>
                        <option selected={status === "pending" ? true : false} value="pending">
                            pending
                        </option>
                        <option value="processed" selected={status === "processed" ? true : false}>
                            processed
                        </option>
                        <option value="delivered" selected={status === "delivered" ? true : false}>
                            delivered
                        </option>
                        <option value="cancelled" selected={status === "cancelled" ? true : false}>
                            cancelled
                        </option>
                    </select>
                </div>
            </div>
            <div className="sub_header___">
                <div className="_btn_">
                    <div>
                        <p>Ordered Items</p>
                    </div>
                </div>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            filterDisplay="row"
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={orderList}
                            selection={selectedRow}
                            onSelectionChange={(e) => setselectedRow(e.value)}
                        >
                            <Column selectionMode="multiple" style={{ width: "3em" }} />
                            <Column field="_id" header="ID" />
                            <Column header="item" body={itemTemplate}></Column>
                            <Column field="quantity" header="Quantity" />
                            <Column field="price" header="Price" />
                            <Column header="Created On" body={dateTemplate} />
                            <Column header="Action" body={actionBodyTemplate} />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OrderDetail;
