import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Route, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppConfig } from "./AppConfig";
// import Login from "./pages/login/Login";

import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingOverlay from "react-loading-overlay";

import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./App.scss";
import Customers from "./pages/customers/Customers";
import User from "./pages/User/User";
import Dashboard from "./components/Dashboard";
import TyreBrands from "./pages/tyreBrands/TyreBrands";
import TyrePattern from "./pages/tyrePattern/TyrePattern";
import Tyres from "./pages/tyres/Tyres";
import VehicleManufacturer from "./pages/vehicleManufacturer/VehicleManufacturer";
import Manage from "./pages/manage/Manage";
import Logs from "./pages/logs/Logs";
import Notices from "./pages/notices/Notices";
import LoginHistory from "./pages/loginHistory/LoginHistory";
import StaticPages from "./pages/staticPages/StaticPages";
import Features from "./pages/features/Features";
import Customer from "./pages/customers/Customer";
import TyreBrand from "./pages/tyreBrands/TyreBrand";
import TyreProfile from "./pages/tyres/TyreProfile";
import TyrePatternProfile from "./pages/tyrePattern/TyrePatternProfile";
import Enquirey from "./pages/enquirey/Enquirey";
import EnquireyDetails from "./pages/enquirey/EnquireyDetails";
import Orders from "./pages/orders/Orders";
import OrderDetail from "./pages/orders/OrderDetail";
import Pagedata from "./pages/staticPages/Pagedata";
import ManufacturerDetails from "./pages/vehicleManufacturer/ManufacturerDetails";
import VehicleModal from "./pages/vehicleManufacturer/VehicleModal";
import EditCarVariant from "./pages/vehicleManufacturer/EditCarVariant";
import AddVehicleVariant from "./pages/vehicleManufacturer/AddVehicleVariant";
import AddVehicleModal from "./pages/vehicleManufacturer/AddVehicleModal";
import Login from "./pages/auth/Login";
import VarientDetails from "./pages/vehicleManufacturer/VarientDetails";
import ModelDetails from "./pages/vehicleManufacturer/ModelDetails";
import Offers from "./pages/Offers/Offers";
import GlobalOffers from "./pages/Offers/GlobalOffers";
import EditOffer from "./pages/Offers/EditOffer";
import EditGlobalOffer from "./pages/Offers/EditGlobalOffer";
import Feature from "./pages/features/Feature";
import Warranty from "./pages/staticPages/AllStaticPages/warranty/Warranty";
import Faqs from "./pages/faqs/Faqs";
import Faq from "./pages/faqs/Faq";
import { useHistory } from "react-router-dom";
import OrderItem from "./pages/orders/OrderItem";
import DeletedTyre from "./pages/tyres/DeletedTyre";
import ReviewModal from "./pages/Review/ReviewModal";

const App = () => {
    const naviagte = useHistory();
    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("light");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();
    const [role, setRole] = useState();
    require("dotenv").config();
    PrimeReact.ripple = true;
    let key = localStorage.getItem("token");
    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (!token) {
            naviagte.push("/auth");
        }
    }, []);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };
    const isDesktop = () => {
        return window.innerWidth >= 992;
    };

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });

    useEffect(() => {
        const role = localStorage.getItem("role");
        setRole(role);
    }, []);

    const getCustomer = () => {
        if (role == "manager" || role === "admin") {
            return [{ label: "Customer", icon: "pi pi-user", to: "/customers" }];
        }
    };
    const getUser = () => {
        if (role == "manager" || role === "admin") {
            return [{ label: "User", icon: "pi pi-user", to: "/user" }];
        }
    };

    const getOffers = () => {
        if (role === "manager" || role === "admin") {
            return [
                { label: "Deals", icon: "pi pi-circle", to: "/offers" },
                {
                    label: "Global Offers",
                    icon: "pi pi-circle",
                    to: "/globaloffers",
                },
            ];
        }
    };

    const getTyres = () => {
        if (role === "manager" || role === "admin" || role === "digital marketing") {
            return [
                { label: "Tyre Brands", icon: "pi pi-circle", to: "/tyrebrands" },
                { label: "Tyre Pattern", icon: "pi pi-circle", to: "/tyrepattern" },
                { label: "Tyre", icon: "pi pi-circle", to: "/tyre" },
                { label: "Deleted Tyres", icon: "pi pi-circle", to: "/deletedTyres" },
            ];
        }
    };

    const getVehicles = () => {
        if (role === "manager" || role === "admin" || role === "digital marketing") {
            return [
                {
                    label: "Vehicle manufacturer",
                    icon: "pi pi-car",
                    to: "/vehiclemanufacturer",
                },
                { label: "Vehicle model", icon: "pi pi-car", to: "/vehiclemodel" },
                { label: "Vehicle variant", icon: "pi pi-car", to: "/addvarient" },
            ];
        }
    };

    const getFaq = () => {
        if (role === "admin" || role === "digital marketing") {
            return [{ label: "Faqs", icon: "pi pi-question", to: "/faqs" }];
        }
    };

    const getManage = () => {
        if (role === "admin" || role === "digital marketing") {
            return [{ label: "Manage", icon: "pi pi-list", to: "/manage" }];
        }
    };

    const getFeatures = () => {
        if (role === "admin" || role === "manager") {
            return [{ label: "Features", icon: "pi pi-server", to: "/features" }];
        }
    };
    const getEnquiry = () => {
        if (role === "admin" || role === "manager" || role === "calling") {
            return [{ label: "Enquiry", icon: "pi pi-question-circle", to: "/enquiry" }];
        }
    };

    const menu = [
        {
            ...(role === "admin" && { items: [{ label: "Dashboard", icon: "pi pi-chart-pie", to: "/" }] }),
        },
        {
            items: getCustomer(),
        },
        {
            items: getUser(),
        },
        {
            items: [
                {
                    label: "Offers",
                    icon: "pi pi-circle",
                    items: getOffers(),
                },
                {
                    label: "Tyres",
                    icon: "pi pi-circle",
                    items: getTyres(),
                },
                {
                    label: "Vehicles",
                    icon: "pi pi-car",
                    items: getVehicles(),
                },
            ],
        },
        {
            items: getFaq(),
        },
        {
            items: getManage(),
        },
        {
            items: getFeatures(),
        },
        {
            items: getEnquiry(),
        },
        {
            items: [{ label: "Orders", icon: "pi pi-shopping-cart", to: "/orders" }],
        },
    ];

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <ToastContainer />
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <>
                {window.location.pathname === "/auth" ? (
                    <Route exact path="/auth" component={Login} />
                ) : (
                    <>
                        <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />
                        <div className="layout-sidebar" onClick={onSidebarClick}>
                            <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                        </div>
                        <div className="layout-main-container">
                            <div className="layout-main">
                                {<Route exact path="/customers" component={Customers} />}
                                {<Route exact path="/user" component={User} />}
                                {<Route exact path="/" component={Dashboard} />}
                                {<Route exact path="/tyrebrands" component={TyreBrands} />}
                                {<Route exact path="/deletedTyres" component={DeletedTyre} />}
                                {<Route exact path="/tyrepattern" component={TyrePattern} />}
                                {<Route exact path="/tyre" component={Tyres} />}
                                {<Route exact path="/vehiclemanufacturer" component={VehicleManufacturer} />}
                                {<Route exact path="/manage" component={Manage} />}
                                {<Route exact path="/logs" component={Logs} />}
                                {<Route exact path="/notices" component={Notices} />}
                                {<Route exact path="/loginhistory" component={LoginHistory} />}
                                {<Route exact path="/pages" component={StaticPages} />}
                                {<Route exact path="/features" component={Features} />}
                                {<Route exact path="/feature/:id" component={Feature} />}
                                {<Route exact path="/customer/:id" component={Customer} />}
                                {<Route exact path="/tyrebrand/:id" component={TyreBrand} />}
                                {<Route exact path="/tyreprofile/:id" component={TyreProfile} />}
                                {<Route exact path="/tyrepatternprofile/:id" component={TyrePatternProfile} />}
                                {<Route exact path="/enquiry" component={Enquirey} />}
                                {<Route exact path="/enquireydetails/:id" component={EnquireyDetails} />}
                                {<Route exact path="/orders" component={Orders} />}
                                {<Route exact path="/orderdetail/:id" component={OrderDetail} />}
                                {<Route exact path="/allStaticPages/:id" component={Pagedata} />}
                                {<Route exact path="/data" component={Warranty} />}
                                {<Route exact path="/privacypolicy" component={Pagedata} />}
                                {<Route exact path="/aboutus" component={Pagedata} />}
                                {<Route exact path="/manufacturerdetails/:id" component={ManufacturerDetails} />}
                                {<Route exact path="/vehiclemodal" component={VehicleModal} />}
                                {<Route exact path="/editcarvarient" component={EditCarVariant} />}
                                {<Route exact path="/addvarient" component={AddVehicleVariant} />}
                                {<Route exact path="/vehiclemodel" component={AddVehicleModal} />}
                                {<Route exact path="/varientdetails/:id" component={VarientDetails} />}
                                {<Route exact path="/modeldetails/:id" component={ModelDetails} />}
                                {<Route exact path="/offers" component={Offers} />}
                                {<Route exact path="/globaloffers" component={GlobalOffers} />}
                                {<Route exact path="/offer/:id" component={EditOffer} />}
                                {<Route exact path="/globaloffer/:id" component={EditGlobalOffer} />}
                                {<Route exact path="/faqs" component={Faqs} />}
                                {<Route exact path="/faq/:id" component={Faq} />}
                                {<Route exact path="/orderItem" component={OrderItem} />}
                                {<Route exact path="/review/edit/:id" component={ReviewModal} />}
                            </div>
                            <AppFooter layoutColorMode={layoutColorMode} />
                        </div>
                    </>
                )}
            </>

            <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>
        </div>
    );
};

export default App;
