import React, { useEffect, useState } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { handleGetRequest } from "../../services/GetTemplate";
import moment from "moment/moment";
import Paginator from "../../components/Paginator";
import Axios from "axios";
import { DEV } from "../../services/constants";
import { handlePostRequest } from "../../services/PostTemplate";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

function Orders() {
    const [selectedRow, setselectedRow] = useState([]);
    const [resData, setResData] = useState([]);
    const home = { icon: "pi pi-home", url: "/" };
    const breadItems = [{ label: "Home", command: (event) => handleClick(event) }, { label: "Orders" }];
    const [skip, setSkip] = useState(0);
    const [total, setTotal] = useState(0);
    const [role, setRole] = useState("");
    const history = useHistory();
    const handledClicked = () => {
        history.push("");
    };
    const getData = async () => {
        const params = {
            skip: skip,
        };
        const res = await handleGetRequest("/allOrders", params);
        const total = await handleGetRequest("/ordersCount");
        setResData(res?.data);
        setTotal(total?.data);
        console.log("response", res?.data);
    };
    useEffect(() => {
        getData();
    }, [skip]);

    const actionBodyTemplate = (rowData) => {
        return (
            <div>
                <Button icon="pi pi-eye" className="p-button-rounded mr-2 Elipse_Icon" onClick={() => history.push(`/orderdetail/${rowData?.order_id}`)} />
            </div>
        );
    };

    const handleClick = (event) => {
        if (event.item.label === "Home") {
            history.push("/");
        }
    };

    const buttonTemplate = (rowData) => {
        return <Button label={rowData?.status} onClick={handledClicked} className="status_button" style={{ width: "4000px" }} />;
    };
    const nameTemplate = (rowData) => {
        return (
            <p style={{ textTransform: "capitalize" }}>
                {rowData?.first_name} {rowData?.last_name}
            </p>
        );
    };
    const dispatch = useDispatch();

    const handledDelete = () => {
        const selectedId = selectedRow.map((val, index) => {
            return val?._id;
        });
        const data = {
            id: selectedId,
        };
        if (selectedId?.length > 0) {
            const res = dispatch(handlePostRequest(data, "/deleteOrder", true, true));
            getData();
            toast.success("Orders deleted.");
            window.location.reload();
        } else {
            toast.info("Please select atleast one Order");
        }
    };

    const timeTemplate = (rowData) => {
        return (
            <div>
                <p>{moment(rowData.updatedAt).format("DD-MM-YY")}</p>
                <p>{moment(rowData.updatedAt).format("hh:mm a")}</p>
            </div>
        );
    };

    const handleskip = (num) => {
        setSkip(num);
    };

    const [values, setValues] = useState({
        order_id: "",
        first_name: "",
        phone: "",
        state: "",
        city: "",
        zipcode: "",
        price: "",
        status: "",
    });

    const temporary = ["order_id", "first_name", "phone", "state", "city", "zipcode", "price", "status"];

    const handleApplyFilter = async (value, names) => {
        const temp = values;
        // temporary.forEach((item) => {
        //     if (item !== names) {
        //         temp[item] = "";
        //     }
        // });
        // setValues(temp);
        temp[names] = value;
        setValues({ ...values, [names]: value });
        const result = await Axios.get(DEV + "/searchOrders", {
            params: {
                ...temp
            },
        });
        setResData(result?.data?.data);
    };

    const handleFilter = (name) => {
        return (
            <input
                style={{
                    width: "100%",
                    height: "37px",
                    borderRadius: "5px",
                    border: "1px solid #cecece",
                }}
                value={values[name]}
                onChange={(e) => handleApplyFilter(e.target.value, name)}
            ></input>
        );
    };

    const paymentTempalte = (rowData) => {
        return (
            <div>
                <p style={{ textTransform: "capitalize" }}>{rowData?.payment === true ? "successful" : "declined"}</p>
            </div>
        );
    };

    useEffect(() => {
        const role = localStorage.getItem("role");
        setRole(role);
    }, []);

    return (
        <>
            <div className="Page__Header">
                <div>
                    <h2>Orders</h2>
                    <BreadCrumb model={breadItems} home={home} />
                </div>
                {role === "admin" && (
                    <div className="Top__Btn">
                        <Button icon="pi pi-trash" iconPos="right" onClick={handledDelete} className="Btn__DarkDelete" style={{ width: "340px" }} />
                    </div>
                )}
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            filterDisplay="row"
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={resData}
                            paginator={true}
                            rows={6}
                            selection={selectedRow}
                            onSelectionChange={(e) => setselectedRow(e.value)}
                        >
                        
                            <Column selectionMode="multiple" style={{ width: "3em" }} />
                            <Column filter field="order_id" header="ID" filterElement={() => handleFilter("order_id")} />
                            <Column filter body={nameTemplate} header="Full Name" filterElement={() => handleFilter("first_name")} />
                            <Column filter field="phone" header="Phone" filterElement={() => handleFilter("phone")} />
                            <Column filter field="state" header="State" filterElement={() => handleFilter("state")} />
                            <Column filter field="city" header="City" filterElement={() => handleFilter("city")} />
                            <Column filter field="zipcode" header="Zip Code" filterElement={() => handleFilter("zipcode")} />
                            <Column filter field="price" header="Price" filterElement={() => handleFilter("price")} />
                            <Column filter body={paymentTempalte} header="payment status" filterElement={() => handleFilter("payment")} />
                            <Column filter body={buttonTemplate} header="Delivery Status" filterElement={() => handleFilter("status")} />
                            <Column header="Created On" body={timeTemplate} />
                            <Column header="Action" body={actionBodyTemplate} />

                        </DataTable>

                        {/* <Paginator data={resData} total={total} skip={skip} handleskip={handleskip} /> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Orders;
