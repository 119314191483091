import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import classNames from "classnames";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";
import { handleGetRequest } from "../../services/GetTemplate";
import Select from "react-select";
import { DEV } from "../../services/constants";

function AddOfferDialogue({ onHideOfferdialogue, onsuccess }) {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [tyres, setTyres] = useState([]);
    const [tyre, setTyre] = useState();
    const [filter, setFilter] = useState({
        ratio: "",
        width: "",
        rimDiameter: ""
    });
    const [ratioOptions, setRatioOptions] = useState([]); 
    const [widthOptions, setWidthOptions] = useState([]);
    const [rimDiameterOptions, setRimDiameterOptions] = useState([]);
    
    const validationSchema = Yup.object().shape({
        newPrice: Yup.string().required("This field is required"),
    });

    const getData = async () => {
        const result = await handleGetRequest("/allTyre");
        const temp = result?.data?.map((tyre) => {
            return { value: tyre?._id, label: `${tyre?.title} - ${tyre?.tyre_id}`};
        });


        const uniqueRatios = [...new Set(result.data.map(tyre => tyre.tyre_profile))];
        const ratioOptions = uniqueRatios.map(ratio => {
            return { value: ratio, label: ratio };
        });

        const uniqueWidths = [...new Set(result.data.map(tyre => tyre.tyre_width))];
        const widthOptions = uniqueWidths.map(width => {
            return { value: width, label: width };
        });

        const uniqueRimDiameters = [...new Set(result.data.map(tyre => tyre.rim_diameter))];
        const rimDiameterOptions = uniqueRimDiameters.map(rimDiameter => {
            return { value: rimDiameter, label: rimDiameter };
        });

        setTyres(temp);
        setRatioOptions(ratioOptions);
        setWidthOptions(widthOptions);
        setRimDiameterOptions(rimDiameterOptions);
    };

    useEffect(() => {
        getData();
    }, []);

    const handleChange = (name, selectedOption) => {
        setFilter({ ...filter, [name]: selectedOption.value });
    };

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            newPrice: "",
        },

        onSubmit: async (data) => {
            const dat = {
                productId: tyre?.value,
                newPrice: data?.newPrice,
            };
            setLoading(true);
            const res = await dispatch(handlePostRequest(dat, "/addDeal", true, true));
            setLoading(false);
            if (res?.success === true) {
                onsuccess();
            }
            // console.log(res);
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleSearch = async () => {
        const apiUrl = `/adminSearch?ratio=${filter.ratio}&width=${filter.width}&rimDiameter=${filter.rimDiameter}`;
        const result = await handleGetRequest(apiUrl);
        const temp = result?.data?.map((tyre) => {
            return { value: tyre?._id, label: `${tyre?.title} - ${tyre?.tyre_id}` };
        });
        setTyres(temp);
    };

    const handleReset = () => {
        setTyre(null);
        setFilter({
            ratio: "",
            width: "",
            rimDiameter: ""
        });
        getData();
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                <div className="p-fluid p-formgrid grid mb-5">
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <Select
                                options={ratioOptions}
                                value={ratioOptions.find(option => option.value === filter.ratio)}
                                onChange={(selectedOption) => handleChange("ratio", selectedOption)}
                                placeholder="Select Ratio"
                                className="Input__Round"
                            />
                            <span style={{ margin: "0 5px" }}></span>
                            <Select
                                options={widthOptions}
                                value={widthOptions.find(option => option.value === filter.width)}
                                onChange={(selectedOption) => handleChange("width", selectedOption)}
                                placeholder="Select Width"
                                className="Input__Round"
                            />
                            <span style={{ margin: "0 5px" }}></span>
                            <Select
                                options={rimDiameterOptions}
                                value={rimDiameterOptions.find(option => option.value === filter.rimDiameter)}
                                onChange={(selectedOption) => handleChange("rimDiameter", selectedOption)}
                                placeholder="Select Diameter"
                                className="Input__Round"
                            />
                            <span style={{ margin: "0 5px" }}></span>
                            <Button label="Search" className="Btn__Dark p-button-sm" onClick={handleSearch} />
                            <span style={{ margin: "0 5px" }}></span>
                            <Button label="Reset" className="Btn__Transparent p-button-sm" onClick={handleReset} />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field" style={{ display: "flex", flexDirection: "column" }}>
                            <label htmlFor="title" className={classNames({ "p-error": isFormFieldValid("title") }, "Label__Text")}>
                                Tyre
                            </label>
                            <Select options={tyres} value={tyre} onChange={setTyre}></Select>
                            {getFormErrorMessage("title")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="newPrice" className={classNames({ "p-error": isFormFieldValid("newPrice") }, "Label__Text")}>
                                New Price
                            </label>
                            <InputText id="newPrice" name="newPrice" value={formik.values.newPrice} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("newPrice") }, "Input__Round")} />
                            {getFormErrorMessage("newPrice")}
                        </div>
                    </div>
                </div>
                <div className="Down__Btn">
                    <Button label="Cancel" className="Btn__Transparent p-button-sm" onClick={(e) => onHideOfferdialogue()} type="button" />
                    <Button label="Create" className="Btn__Dark p-button-sm" disabled={loading} />
                </div>
            </form>
        </>
    );
}

export default AddOfferDialogue;
