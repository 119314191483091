import React from "react";

export const AppFooter = (props) => {
    return (
        <div className="layout-footer">
            {/* <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo-dark.svg' : 'assets/layout/images/logo-white.svg'} alt="Logo" height="20" className="mr-2" />
            by
            <span className="font-medium ml-2">PrimeReact</span> */}
            <p>©2022 Tyrewaale | All Rights Reserved</p>
            <div className="footer_links">
                {/* <p>About</p>
                <p>Support</p>
                <p>Contact</p> */}
            </div>
        </div>
    );
};
