import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { handleGetRequest } from "../services/GetTemplate";

const Dashboard = () => {
    const [stats, setStats] = useState({
        users: 0,
        tyres: 0,
        brands: 0,
        vehicles: 0,
        orders: 0,
        login: 0,
    });
    const [dataset, setDataSet] = useState([]);

    const getData = async () => {
        const users = await handleGetRequest("/countUsers");
        const orders = await handleGetRequest("/ordersCount");
        const tyre = await handleGetRequest("/countTyres");
        const brands = await handleGetRequest("/countManufacturer");
        const vehicles = await handleGetRequest("/countVarient");
        const login = await handleGetRequest("/countlogs");
        const sts = await handleGetRequest("/userStats");
        setDataSet(sts?.data);
        setStats({
            users: users?.data,
            orders: orders?.data,
            tyres: tyre?.data,
            vehicles: vehicles?.data,
            brands: brands?.data,
            login: login?.data,
        });
        setBasicData({
            labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            datasets: [
                {
                    label: "users",
                    data: sts?.data,
                    fill: false,
                    borderColor: "#42A5F5",
                    tension: 0.4,
                },
            ],
        });
    };

    useEffect(() => {
        getData();
    }, []);

    console.log(dataset);
    const [basicData, setBasicData] = useState({
        labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        datasets: [
            {
                label: "users",
                data: dataset,
                fill: false,
                borderColor: "#42A5F5",
                tension: 0.4,
            },
        ],
    });

    const getLightTheme = () => {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: "#495057",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
            },
        };

        let multiAxisOptions = {
            stacked: false,
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: "#495057",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
            },
        };

        return {
            basicOptions,
            multiAxisOptions,
        };
    };

    const { basicOptions, multiAxisOptions } = getLightTheme();
    return (
        <>
            <div className="grid_view">
                <div className="card1">
                    <i className="pi pi-user home_icon" />
                    <p>users</p>
                    <p>{stats?.users}</p>
                </div>
                <div className="card2">
                    <i className="pi pi-history home_icon" />
                    <p>login history</p>
                    <p>{stats?.login}</p>
                </div>
                <div className="card3">
                    <i className="pi pi-circle home_icon" />
                    <p>tyres</p>
                    <p>{stats?.tyres}</p>
                </div>
                <div className="card4">
                    <i className="pi pi-circle home_icon" />
                    <p>tyre brands</p>
                    <p>{stats?.brands}</p>
                </div>
                <div className="card5">
                    <i className="pi pi-car home_icon" />
                    <p>cars</p>
                    <p>{stats?.vehicles}</p>
                </div>
                <div className="card6">
                    <i className="pi pi-shopping-cart home_icon" />
                    <p>orders</p>
                    <p>{stats?.orders}</p>
                </div>
            </div>
            <div className="graphs_section">
                <div className="left_">
                    <h5>Monthly users registered</h5>
                    <Chart type="line" data={basicData} options={basicOptions} />
                </div>
                <div className="right"></div>
            </div>
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(Dashboard, comparisonFn);
