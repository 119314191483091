import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import { handlePutRequest } from "../../services/PutTemplate";
import { handleGetRequest } from "../../services/GetTemplate";

const ReviewModal = () => {
    const location = useLocation();
    const history = useHistory();
    const { id } = useParams();

    const [rowData, setRowData] = useState(location.state?.rowData || {});
    const [tyreData, setTyreData] = useState(location.state?.tyredata || {});
    const [editable, setEditable] = useState(false);
    const [images, setImages] = useState([]);


    const makecall = async (image) => {
        const result = await handleGetRequest(`/getImage?image=${image}`);
        // console.log("give response",result);
        setImages(result?.data?.url);
    };
    const processimages = async (images) => {
        let result;
        let temp = images;
        for (let i = 0; i < images?.length; i++) {
            result = await makecall(images[i]);
            temp[i] = result;
        }
        return temp;
    };


    // console.log("show me tyre", tyreData);

    useEffect(() => {
        if (rowData) {
            // console.log("Row data:", rowData);
            Object.keys(rowData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, rowData[key]);
                }
            });
        }

       
    }, [rowData]);

    useEffect(() => {
        const ima = tyreData?.images?.map((im) => {
            return im?.image;
        });
        const t= processimages(ima);
        setImages(t);
    }, []);

    const validationSchema = Yup.object().shape({
        title: Yup.string().required("This field is required"),
        description: Yup.string().required("This field is required"),
        name: Yup.string().required("This field is required"),
        status: Yup.string().required("This field is required"),
        Low_noise: Yup.number().required("This field is required").min(0).max(5),
        ride_comfort: Yup.number().required("This field is required").min(0).max(5),
        value_for_money: Yup.number().required("This field is required").min(0).max(5),
        wet_tyre: Yup.number().required("This field is required").min(0).max(5),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            title: "",
            description: "",
            name: "",
            status: "",
            Low_noise: 0,
            ride_comfort: 0,
            value_for_money: 0,
            wet_tyre: 0,
        },
        onSubmit: async (data) => {
            // Calculate the average rating
            const averageRating = (
                (parseFloat(data.Low_noise) +
                parseFloat(data.ride_comfort) +
                parseFloat(data.value_for_money) +
                parseFloat(data.wet_tyre)) / 4
            ).toFixed(2); // toFixed(2) ensures the rating has two decimal places
    
            // Add the average rating to the data object
            const dataWithRating = {
                ...data,
                rating: averageRating,
            };
    
            try {
                const response = await handlePutRequest(
                    { ...dataWithRating, _id: rowData._id },
                    `/editReview/${tyreData._id}`
                );
    
                if (response?.success) {
                    toast.success("Review updated successfully.");
                    // Update local state with new data from location.state
                    const updatedRowData = location.state?.rowData || {};
                    const updatedTyreData = location.state?.tyredata || {};
    
                    setRowData(updatedRowData);
                    setTyreData(updatedTyreData);
                    history.push(`/tyreprofile/${tyreData?.tyre_id}`);
    
                    // Update form values with new data
                    Object.keys(updatedRowData).forEach((key) => {
                        if (formik.initialValues.hasOwnProperty(key)) {
                            formik.setFieldValue(key, updatedRowData[key]);
                        }
                    });
                } else {
                    toast.error(response.message || "Failed to update review.");
                }
            } catch (error) {
                toast.error("An error occurred while updating the review.");
            }
        },
    });
    

    const handleNumberChange = (e) => {
        const { name, value } = e.target;
        const newValue = Math.min(value, 5);
        formik.setFieldValue(name, newValue);
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleCancel = () => {
        history.push(`/tyreprofile/${tyreData?.tyre_id}`);
        };

    return (
        <>
            <div className="customer_header__">
                <h2>Edit Review</h2>
            </div>
            <div className="customer_details_section">
            <div className="left_section">
            <img src={images} />
                    <div className="id_section">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <p>ID:</p>
                            <p>{tyreData?.tyre_id}</p>
                        </div>
                        <div>
                            <Button label="Active" className="green_btn"></Button>
                        </div>
                    </div>
                </div>

                <div className="right_section">
                    <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                        <div className="form__">
                            <div className="form_left">
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="title" className={classNames({ "p-error": isFormFieldValid("title") }, "Label__Text")}>
                                        Title
                                    </label>
                                    <InputText id="title" name="title" value={formik.values.title} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("title") }, "Input__Round")} />
                                    {getFormErrorMessage("title")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="description" className={classNames({ "p-error": isFormFieldValid("description") }, "Label__Text")}>
                                        Description
                                    </label>
                                    <InputText id="description" name="description" value={formik.values.description} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("description") }, "Input__Round")} />
                                    {getFormErrorMessage("description")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="name" className={classNames({ "p-error": isFormFieldValid("name") }, "Label__Text")}>
                                        Name
                                    </label>
                                    <InputText id="name" name="name" value={formik.values.name} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("name") }, "Input__Round")} />
                                    {getFormErrorMessage("name")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="status" className={classNames({ "p-error": isFormFieldValid("status") }, "Label__Text")}>
                                        Status
                                    </label>
                                    <Dropdown
                                        id="status"
                                        name="status"
                                        value={formik.values.status}
                                        onChange={formik.handleChange}
                                        options={[
                                            { label: "Approved", value: "approved" },
                                            { label: "Pending", value: "pending" },
                                            { label: "Rejected", value: "rejected" },
                                        ]}
                                        className={classNames({ "p-invalid": isFormFieldValid("status") })}
                                    />
                                    {getFormErrorMessage("status")}
                                </div>
                            </div>
                            <div className="form_right">
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="Low_noise" className={classNames({ "p-error": isFormFieldValid("Low_noise") }, "Label__Text")}>
                                        Low Noise
                                    </label>
                                    <InputText id="Low_noise" name="Low_noise" type="number" value={formik.values.Low_noise} onChange={handleNumberChange} className={classNames({ "p-invalid": isFormFieldValid("Low_noise") }, "Input__Round")} />
                                    {getFormErrorMessage("Low_noise")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="ride_comfort" className={classNames({ "p-error": isFormFieldValid("ride_comfort") }, "Label__Text")}>
                                        Ride Comfort
                                    </label>
                                    <InputText id="ride_comfort" name="ride_comfort" type="number" value={formik.values.ride_comfort} onChange={handleNumberChange} className={classNames({ "p-invalid": isFormFieldValid("ride_comfort") }, "Input__Round")} />
                                    {getFormErrorMessage("ride_comfort")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="value_for_money" className={classNames({ "p-error": isFormFieldValid("value_for_money") }, "Label__Text")}>
                                        Value for Money
                                    </label>
                                    <InputText id="value_for_money" name="value_for_money" type="number" value={formik.values.value_for_money} onChange={handleNumberChange} className={classNames({ "p-invalid": isFormFieldValid("value_for_money") }, "Input__Round")} />
                                    {getFormErrorMessage("value_for_money")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="wet_tyre" className={classNames({ "p-error": isFormFieldValid("wet_tyre") }, "Label__Text")}>
                                        Wet Tyre
                                    </label>
                                    <InputText id="wet_tyre" name="wet_tyre" type="number" value={formik.values.wet_tyre} onChange={handleNumberChange} className={classNames({ "p-invalid": isFormFieldValid("wet_tyre") }, "Input__Round")} />
                                    {getFormErrorMessage("wet_tyre")}
                                </div>
                            </div>
                        </div>
                        <div className="Down__Btn">
                            <Button label="Cancel" className="Btn__Transparent" onClick={handleCancel} />
                            <Button label="Update" className="Btn__Dark" type="submit" />
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ReviewModal;
